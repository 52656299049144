import * as React from "react";
import { Container } from "../../styles/element";
import { ContentWrapper, CookieBannerWrapper } from "./styles";

const STORAGE = "ifpi";

export default function CookieBanner() {
  const cookieBannerRef = React.useRef<any>();
  const handleCookieBanner = React.useCallback(() => {
    localStorage.setItem(STORAGE, "true");
    if (!cookieBannerRef.current) return;
    cookieBannerRef.current.style.transform = "translateY(20vh)";
    cookieBannerRef.current.style.visibility = "hidden";
    cookieBannerRef.current.style.pointerEvents = "none";
  }, []);

  React.useEffect(() => {
    const item = localStorage.getItem(STORAGE);
    if (item) return;
    if (!cookieBannerRef.current) return;
    const timeout = setTimeout(() => {
      cookieBannerRef.current.style.transform = "translate(0)";
      cookieBannerRef.current.style.visibility = "visible";
      cookieBannerRef.current.style.pointerEvents = "all";
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <CookieBannerWrapper ref={cookieBannerRef}>
      <Container>
        <ContentWrapper>
          <p>
            This website uses cookies to understand your use of our website and
            to give you a better experience. By continuing to use the site or
            closing this banner, you are agreeing to our use of cookies.
          </p>
          <button onClick={handleCookieBanner}>Close</button>
        </ContentWrapper>
      </Container>
    </CookieBannerWrapper>
  );
}
