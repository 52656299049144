import React from "react";
import { HomeWrapper } from "./styles";
import Page from "../../components/page/page";
import Header from "../../components/header/header";
import content from "../../content/content";
import Reports from "../../components/reports/reports";
import Release from "../../components/release/release";
import Footer from "../../components/footer/footer";
import getHomePage from "../../services/get-home";
import { Cards } from "../../components/cards/cards";

export default function Home() {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any>(null);

  React.useEffect(() => {
    setLoading(true);
    getHomePage().then(({ data }) => {
      setData(data);
      setLoading(false);
    });
  }, []);

  if (loading) return <div>Loading.....</div>;

  if (!data)
    return (
      <div>Something went wrong. We are working to bring the website back.</div>
    );

  return (
    <HomeWrapper>
      <Page>
        {data?.header && (
          <Header
            content={data?.header?.content ?? content.header.content}
            artists={data?.header?.artist ?? content.header.artists}
            title={data?.header?.title ?? content.header.title}
            backgroundColors={
              data?.header?.backgroundColor ?? content.header.backgroundColors
            }
          />
        )}
        {data?.report && (
          <Reports
            image={
              data?.report?.image?.data?.attributes?.url ?? content.report.image
            }
            backgroundColors={
              data?.report?.backgroundColor ?? content.report.backgroundColors
            }
            reports={data?.report?.reportFile ?? content.report.reports}
            popUp={data?.report?.popUp ?? content.report.popUp}
          />
        )}
        {data?.cards && (
          <Cards
            data={data?.cards ?? content.cards}
            popup={data?.report?.popUp ?? content.report.popUp}
          />
        )}
        {data?.release && (
          <Release
            backgroundColors={
              data?.release?.backgroundColors ??
              content.release.backgroundColors
            }
            video={data?.release?.video ?? content.release.video}
          />
        )}
        <Footer socials={content.footer.socials} />
      </Page>
    </HomeWrapper>
  );
}
