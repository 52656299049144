import React from "react";
import { TitleContent } from "./styles";

type Props = {
  content: any;
};

export default function Title({ content }: Props) {
  return <TitleContent dangerouslySetInnerHTML={{ __html: content }} />;
}
