import React from "react";
import { BackgroundSphere, BackgroundWrapper } from "./styles";

type Props = {
  backgroundColors: { color: string; id: string }[];
};

export default function Background({ backgroundColors }: Props) {
  return (
    <BackgroundWrapper>
      {backgroundColors.map(({ color }, index) => {
        return <BackgroundSphere key={index} color={color} index={index} />;
      })}
    </BackgroundWrapper>
  );
}
