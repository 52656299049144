import styled from "styled-components";

type Props = {
  maxWidth?: number;
};

export const Container = styled.div<Props>`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "1300px")};
  padding: 0 2rem;
  margin: 0 auto;
  position: relative;

  @media (max-width: 800px) {
    padding: 0;
  }
`;
