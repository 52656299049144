import styled from "styled-components";
import vars from "../../styles/vars";

type Props = {
  variant: "primary" | "secondary";
  $buttonColor?: string;
  $buttonCopyColor?: string;
  $buttonHoverColor?: string;
};

export const ButtonWrapper = styled.button<Props>`
  display: inline-block;
  background-color: ${({ $buttonColor }) =>
    $buttonColor ? $buttonColor : `${vars.colors.yellow}`};
  color: ${({ $buttonCopyColor }) =>
    $buttonCopyColor === "white"
      ? `${vars.colors.white}`
      : `${vars.colors.black}`};
  font-family: ${vars.fontFraunces};
  padding: 0.7em 1em;
  max-width: max-content;
  cursor: pointer;
  border: none;
  font-size: 0.8em;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;

  @media (max-width: 1000px) {
    font-size: 0.7rem;
  }

  @media (max-width: 900px) {
    font-size: 0.6rem;
  }

  @media (max-width: 800px) {
    font-size: 0.87rem;
  }

  @media (max-width: 700px) {
    max-width: 100%;
    text-align: center;
    font-size: 1.5rem;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${({ $buttonHoverColor }) =>
      $buttonHoverColor ? $buttonHoverColor : `${vars.colors.primary}`};
    color: ${({ $buttonCopyColor }) =>
      $buttonCopyColor === "white"
        ? `${vars.colors.black}`
        : `${vars.colors.white}`};
  }

  &:disabled:hover {
    background-color: ${vars.colors.primary};
  }
`;
