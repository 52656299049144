import React from "react";
import { PageWrapper } from "./styles";
import ReactGA from "react-ga4";
import CookieBanner from "./cookie-banner";

type Props = {
  children: any;
};

export default function Page({ children }: Props) {
  React.useEffect(() => {
    console.log("Checking cookies...");
    ReactGA.initialize([
      {
        trackingId: "G-NF2GYEQ125",
      },
    ]);
    ReactGA.set({ page: "home" });
    ReactGA.send({ hitType: "pageview", page: "home" });
    console.log("Check completed...");
  }, []);

  return (
    <PageWrapper>
      {children}
      <CookieBanner />
    </PageWrapper>
  );
}
