const content = {
  header: {
    backgroundColors: [
      { color: "#44d5d0", id: "1" },
      { color: "#2a7876", id: "1" },
      { color: "#17938f", id: "1" },
    ],
    title: `GLOBAL MUSIC REPORT 2024`,
    artists: [
      {
        attributes: {
          url: "/assets/images/pattern.png",
        },
      },
      {
        attributes: {
          url: "/assets/images/Dua-Lipa.png",
        },
      },
      {
        attributes: {
          url: "/assets/images/Yng-Lvcas.png",
        },
      },
      {
        attributes: {
          url: "/assets/images/IVE-Baddie.png",
        },
      },
      {
        attributes: {
          url: "/assets/images/miley-1.png",
        },
      },
      {
        attributes: {
          url: "/assets/images/Taylor-Swift.png",
        },
      },
      {
        attributes: {
          url: "/assets/images/rema-1.png",
        },
      },
      {
        attributes: {
          url: "/assets/images/sza-sme-1.png",
        },
      },
      {
        attributes: {
          url: "/assets/images/zach-1.png",
        },
      },
      {
        attributes: {
          url: "/assets/images/Karol-G.png",
        },
      },
    ],
    content: `The role of record companies in empowering artists and supporting the growth of the wider music industry has never been more important than it is in today’s highly competitive, global landscape.`,
  },
  report: {
    image: "/assets/Premium-Edition-1.png",
    backgroundColors: [
      { color: "#e16d6b", id: "1" },
      { color: "#e16d6b", id: "1" },
      { color: "#e16d6b", id: "1" },
    ],
    reports: [
      {
        title: "State of The industry",
        content: `<p>A free report, providing an overview of top line music revenues in 2022, together with a dive into some of key elements shaping the industry today and commentary from leading figures in the recorded music business.</p>`,
        type: "download",
        buttonText: "Download now",
        file: "/assets/files/reportpdf.pdf",
      },
      {
        title: "Premium Edition",
        content: `<p>An extended version of the report including detailed data and analysis of recorded music revenues at national, regional and global level. Offering granular data on global charts, revenue formats and 50+ music markets. Available to purchase.</p>`,
        type: "link",
        buttonText: "Shop now",
        link: "https://gmr.ifpi.org",
      },
    ],
    popUp: `By clicking on the checkbox provided you acknowledge that you have read and understood the Global Music Report <a href="https://gmr.ifpi.org/terms-of-use" target="_blank">Terms of Use</a> and the <a href="https://gmr.ifpi.org/content-usage" target="_blank">Content Usage Rules</a> and that you agree to be bound by them`,
  },
  cards: [
    {
      imageLeft: {
        data: {
          attributes: {
            url: "/assets/images/cards/card-1-left.png",
          },
        },
      },
      imageRight: {
        data: {
          attributes: {
            url: "/assets/images/cards/card-1-right.png",
          },
        },
      },
      imageAlt: "The artist partnership and beyond",
      bgColor: "#D26159",
      title: "Empowering great talent and incredible music",
      content:
        "Today, artists have an incredible amount of choice in how to create, record and share their music with fans. Working with a record label is not a necessity for an artist; it becomes an active choice they are making to collaborate with a label partner and benefit from their broad network of talented and experienced teams invested in their creative and commercial success.",
      buttonColor: "#E2302E",
      buttonHoverColor: "#F3BC25",
      buttonCopyColor: "white",
      button1: {
        text: "Read more",
        file: {
          data: {
            attributes: {
              url: "/assets/files/reportpdf.pdf",
            },
          },
        },
      },
      button2: {
        text: "Download the full State of the Industry report",
        file: {
          data: {
            attributes: {
              url: "/assets/files/reportpdf.pdf",
            },
          },
        },
      },
      layout: "column",
    },
    {
      imageLeft: {
        data: {
          attributes: {
            url: "/assets/images/cards/card-2-left.png",
          },
        },
      },
      imageRight: {
        data: {
          attributes: {
            url: "/assets/images/cards/card-2-right.png",
          },
        },
      },
      imageAlt: "Regional overview",
      bgColor: "#E77E32",
      title:
        "The globally connected nature of today’s music ecosystem is now well established.",
      content: `Today, almost anywhere in the world, fans can enjoy a rich, diverse mix of music; whether from their very own, very local music cultures, or something that has travelled across the world, brought to them by the networks and infrastructure of interconnected record companies. The combination of these growing global connections and very diverse local markets means the role of record companies is more vital than ever in helping artists to navigate the path from breaking locally to succeeding in an increasingly complex global landscape.`,
      buttonColor: "#F3BC25",
      buttonHoverColor: "#E94E4C",
      buttonCopyColor: "black",
      button1: {
        text: "Read more",
        file: {
          data: {
            attributes: {
              url: "/assets/files/reportpdf.pdf",
            },
          },
        },
      },
      button2: {
        text: "Download the full State of the Industry report",
        file: {
          data: {
            attributes: {
              url: "/assets/files/reportpdf.pdf",
            },
          },
        },
      },
      layout: "column",
    },
    {
      imageLeft: {
        data: {
          attributes: {
            url: "/assets/images/cards/card-3-left.png",
          },
        },
      },
      imageRight: {
        data: {
          attributes: {
            url: "/assets/images/cards/card-3-right.png",
          },
        },
      },
      imageAlt: "RECORD COMPANIES DRIVING INNOVATION",
      bgColor: "#A7D58A",
      content: `Innovation is at the heart of every record company and in the DNA of every department – from Marketing to Business Affairs, A&R to Accounting. It has gone from lightning in a bottle to engine oil. No longer defined by technology, innovation drives working practices, business models, fan interaction, ethical and ecological agendas and much more.`,
      buttonColor: "#17723C",
      buttonHoverColor: "#E94E4C",
      buttonCopyColor: "white",
      button1: {
        text: "Read more",
        file: {
          data: {
            attributes: {
              url: "/assets/files/reportpdf.pdf",
            },
          },
        },
      },
      button2: {
        text: "Download the full State of the Industry report",
        file: {
          data: {
            attributes: {
              url: "/assets/files/reportpdf.pdf",
            },
          },
        },
      },
      layout: "column",
    },
    {
      imageLeft: {
        data: {
          attributes: {
            url: "/assets/images/cards/card-4-left.png",
          },
        },
      },
      imageAlt:
        "TODAY’S RECORD COMPANIES INVESTING TIME, ENERGY & RESOURCES IN MUSIC",
      bgColor: "#1D92D2",
      buttonColor: "#253B89",
      buttonHoverColor: "#B2DBF5",
      buttonCopyColor: "white",
      button1: {
        text: "Read more",
        file: {
          data: {
            attributes: {
              url: "/assets/files/reportpdf.pdf",
            },
          },
        },
      },
      button2: {
        text: "Download the full State of the Industry report",
        file: {
          data: {
            attributes: {
              url: "/assets/files/reportpdf.pdf",
            },
          },
        },
      },
      layout: "grid",
    },
    {
      imageLeft: {
        data: {
          attributes: {
            url: "/assets/images/cards/card-5-left.png",
          },
        },
      },
      imageRight: {
        data: {
          attributes: {
            url: "/assets/images/cards/card-5-right.png",
          },
        },
      },
      imageAlt:
        "CREATING AN ENVIRONMENT IN WHICH MUSIC AND CREATORS CAN THRIVE",
      bgColor: "#FFEED4",
      content: `The landscape for music is evolving at an unprecedented pace. It brings new and exciting opportunities for artists and their fans worldwide. However, it also presents challenges to ensuring that music’s value is properly recognised and that those who invest time, effort, resources and talent in creating and releasing music do not see their work exploited or used without consent.`,
      buttonColor: "#81C956",
      buttonHoverColor: "#213862",
      buttonCopyColor: "black",
      button1: {
        text: "Read more",
        file: {
          data: {
            attributes: {
              url: "/assets/files/reportpdf.pdf",
            },
          },
        },
      },
      button2: {
        text: "Download the full State of the Industry report",
        file: {
          data: {
            attributes: {
              url: "/assets/files/reportpdf.pdf",
            },
          },
        },
      },
      layout: "column",
    },
  ],
  release: {
    backgroundColors: [
      { color: "#00a7a2", id: "1" },
      { color: "#00a7a2", id: "1" },
      { color: "#00a7a2", id: "1" },
    ],
    video: {
      source: "https://storage.finervision.com/ifpi/GMR_2023_v5-opt.mp4",
      title: "GLOBAL MUSIC REPORT HIGHLIGHTS VIDEO",
      content: `Watch a three minutes video of report highlights and key stats.`,
    },
  },
  footer: {
    socials: [
      {
        logo: "/assets/icons/facebook.svg",
        url: "https://www.facebook.com/IFPIOrg",
      },
      {
        logo: "/assets/icons/twitter.svg",
        url: "https://twitter.com/i/flow/lite/verify_password",
      },
      {
        logo: "/assets/icons/instagram.svg",
        url: "https://www.instagram.com/ifpi_org",
      },
    ],
  },
};

export default content;
