import React from "react";
import { PopUpBackground, PopUpBox, PopUpContent, Close } from "./popup.styles";
import Button from "../button/button";
import { downloadBlob } from "../../utils";
import markedToHtml from "../../services/marked";

type PopUpProps = {
  show: boolean;
  onClose: () => void;
  popUp: string;
  downloadFile: string;
};

export default function PopUp({
  show,
  onClose,
  popUp,
  downloadFile,
}: PopUpProps) {
  const [open, setOpen] = React.useState(false);
  const [agree, setAgree] = React.useState(false);


  React.useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  const onChange = React.useCallback(() => {
    setAgree((prevState) => !prevState);
  }, []);

  const onSubmit = React.useCallback(() => {
    window.open(downloadFile, "_blank");
    onClose();
  }, [downloadFile, onClose]);

  // Add troubles adding target blank to anchor tags
  // in markdown on the cms, hence this hack.
  React.useEffect(() => {
    function handleClicked(this: HTMLAnchorElement, e: Event) {
      this.target = "_blank";
    }

    const anchor = document.getElementsByTagName("a");
    for (let i = 0; i < anchor.length; i++) {
      anchor[i].addEventListener("click", handleClicked);
    }

    return () => {
      for (let i = 0; i < anchor.length; i++) {
        anchor[i].removeEventListener("click", handleClicked);
      }
    };
  }, []);

  return (
    <PopUpBackground show={open}>
      <PopUpBox show={open}>
        <Close onClick={() => onClose()}>X</Close>
        <PopUpContent
          dangerouslySetInnerHTML={{ __html: markedToHtml(popUp) }}
        />
        <label htmlFor="agree">
          <input
            type="checkbox"
            name="agree"
            id="agree"
            onChange={onChange}
            checked={agree}
          />
          <span>I agree to the terms above</span>
        </label>
        <Button onClick={onSubmit} disabled={!agree}>
          Submit now
        </Button>
      </PopUpBox>
    </PopUpBackground>
  );
}
