import styled from "styled-components";
import vars from "../../styles/vars";
import { ButtonWrapper } from "../button/styles";

export const ReportsWrapper = styled.section`
  padding-bottom: 2em;

  @media (max-width: 700px) {
    padding-bottom: 0;
  }
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: ${vars.colors.red};
`;

export const ReportsContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 12em 4em 3em 4em;

  @media (max-width: 700px) {
    padding: 3em 1em 2em 1em;
  }
`;

export const ImageHolder = styled.div`
  position: relative;
  margin-top: -4em;
  z-index: ${vars.zIndex.report};

  img {
    position: relative;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: ${vars.zIndex.report};
    height: 20em;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

export const FlexContainer = styled.div`
  position: relative;
  z-index: ${vars.zIndex.report};
  display: flex;
  justify-content: center;
  margin: auto;

  & > div {
    flex-basis: 50%;
    &:first-child {
      margin-right: 1em;
    }

    &:last-child {
      margin-left: 1em;
    }
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    gap: 2.5em;

    & > div {
      flex-basis: unset;
      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
      }
    }
  }
`;

export const Report = styled.div`
  max-width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${ButtonWrapper} {
    margin-top: 1.5em;

    @media (max-width: 700px) {
      align-self: stretch;
    }
  }
`;

type ReportSectionProps = {
  $index: number;
};

export const ReportSection = styled.div<ReportSectionProps>`
  & img {
    display: none;

    @media (max-width: 700px) {
      display: flex;
      height: 20em;
      margin-bottom: 3rem;

      display: ${({ $index }) => $index === 0 && "none"};
    }
  }
`;

export const Title = styled.h2`
  font-family: ${vars.fontKamino};
  color: ${vars.colors.white};
  margin-bottom: 0.5em;

  @media (max-width: 700px) {
    font-size: 3em;
    font-weight: 200;
  }
`;

export const Content = styled.div`
  font-family: ${vars.fontFraunces};
  font-weight: 200;
  letter-spacing: 0.3px;
  line-height: 120%;
  color: ${vars.colors.white};
  max-width: 80%;

  @media (max-width: 700px) {
    max-width: 100%;
    font-size: 1.8em;
  }
`;
