import React from "react";
import { Container } from "../../styles/element";
import {
  BackgroundContainer,
  Bottom,
  BottomContent,
  BottomContentContainer,
  ContentContainer,
  ReleaseWrapper,
  Video,
  VideoContainer,
} from "./styles";
import Background from "../background/background";
import { useInView } from "react-intersection-observer";
import Animate from "../animate/animate";

type VideoProps = {
  source: string;
  title: string;
  content: string;
};

type Props = {
  backgroundColors: { color: string; id: string }[];
  video: VideoProps;
};

export default function Release({
  backgroundColors,
  video,
}: Props) {

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <ReleaseWrapper>
      <Container>
        <ContentContainer>
          <BackgroundContainer>
            <Background backgroundColors={backgroundColors} />
          </BackgroundContainer>
          <Bottom>
            <Animate className={`fade-up fade-up-3 wd-100`} isVisible={inView}>
              <BottomContentContainer ref={ref}>
                <h3>{video.title}</h3>
                <BottomContent
                  dangerouslySetInnerHTML={{
                    __html: `<p>${video.content}</p>`,
                  }}
                />
              </BottomContentContainer>
            </Animate>
            <Animate className={`fade-up fade-up-2`} isVisible={inView}>
              <VideoContainer>
                <Video src={"/assets/GMR_20240325_GLITTER-opt.mp4"} playsInline={true} controls={true} />
              </VideoContainer>
            </Animate>
          </Bottom>
        </ContentContainer>
      </Container>
    </ReleaseWrapper>
  );
}
