import React from "react";
import { useInView } from "react-intersection-observer";
import { Container } from "../../styles/element";
import {
  Artist,
  ArtistsWrapper,
  HeaderInner,
  HeaderWrapper,
  Image,
  Logo,
  LogoWrapper,
  ParagraphContainer,
  TitleContainer,
} from "./styles";
import Background from "../background/background";
import Title from "../title/title";
import Content from "../content/content";
import Animate from "../animate/animate";
import defaultContent from "../../content/content";

type ArtistT = {
  attributes: {
    url: string;
  };
};

type Props = {
  title: string;
  artists: {
    artist: { data: ArtistT[] };
  }[];
  content: string;
  backgroundColors: { color: string; id: string }[];
};

export default function Header({
  title,
  artists,
  content,
  backgroundColors,
}: Props) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <HeaderWrapper ref={ref}>
      <Background backgroundColors={backgroundColors} />
      <Container>
        <HeaderInner>
          <LogoWrapper>
            <Logo src="/assets/logos/ifpi-logo-white.svg" alt="IFPI" />
          </LogoWrapper>
          <ArtistsWrapper>
            {artists?.map((artist, index) => {
              return (
                <Animate
                  className={`lazy-load lazy-load-${index + 1}`}
                  isVisible={inView}
                  key={index}
                >
                  <Artist index={index + 1}>
                    <Image
                      src={artist?.artist.data?.[0].attributes.url}
                      alt="Artist"
                      index={index + 1}
                    />
                  </Artist>
                </Animate>
              );
            })}
          </ArtistsWrapper>
          <TitleContainer>
            <Animate className="fade-up fade-up-7" isVisible={inView}>
              <Title content={`<h1 style="color: white">${title}</h1>`} />
            </Animate>
          </TitleContainer>
          <ParagraphContainer>
            <Animate className="fade-up fade-up-3" isVisible={inView}>
              <Content content={`<p>${content}</p>`} />
            </Animate>
          </ParagraphContainer>
        </HeaderInner>
      </Container>
    </HeaderWrapper>
  );
}
