import styled from "styled-components";
import vars from "../../styles/vars";

export const TitleContent = styled.div`
  h1 {
    font-weight: 400;
    font-size: 3em;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0;
    max-width: 50%;
    font-family: ${vars.fontKamino};

    @media (max-width: 900px) {
      font-size: 2rem;
      max-width: 12rem;
    }

    @media (max-width: 700px) {
      font-size: 4.5em;
      max-width: 100%;
      line-height: 140%;
    }
  }
`;
