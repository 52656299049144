import React from "react";
import { Container } from "../../styles/element";
import {
  FlexContainer,
  ImageHolder,
  ReportsContainer,
  ReportsWrapper,
  Report,
  Title,
  Content,
  ReportSection,
  BackgroundContainer,
} from "./styles";
import Background from "../background/background";
import Button from "../button/button";
import Animate from "../animate/animate";
import { useInView } from "react-intersection-observer";
import { ImageFile } from "../../types";
import PopUp from "../popup/popup";

type ReportProps = {
  title: string;
  content: string;
  type: string;
  buttonText: string;
  file?: ImageFile;
  link?: string;
};

type Props = {
  image: string;
  backgroundColors: { color: string; id: string }[];
  reports: ReportProps[];
  popUp: string;
};

export default function Reports({
  image,
  backgroundColors,
  reports,
  popUp,
}: Props) {
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [downloadFile, setDownloadFile] = React.useState("");
  const handleClicked = React.useCallback((report: ReportProps) => {
    return () => {
      if (report.type === "link") {
        window.open(report.link, "_blank");
      } else if (report.file) {
        setDownloadFile(report?.file?.data?.attributes?.url);
        setShowPopUp(true);
      }
    };
  }, []);

  React.useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.code === "Escape") {
        setShowPopUp(false);
      }
    }
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <>
      <ReportsWrapper>
        <Container>
          <Animate className={`fade-up`} isVisible={inView}>
            <ImageHolder>
              <img src={image} alt="Report" />
            </ImageHolder>
          </Animate>

          <ReportsContainer ref={ref}>
            <BackgroundContainer>
              <Background backgroundColors={backgroundColors} />
            </BackgroundContainer>
            <FlexContainer>
              {reports.map((report, index) => {
                return (
                  <Animate
                    className={`fade-up fade-up-${index + 1}`}
                    isVisible={inView}
                    key={index}
                  >
                    <Report>
                      <ReportSection $index={index}>
                        <img src={image} alt="Report" />
                        <Title>{report.title}</Title>
                        <Content
                          dangerouslySetInnerHTML={{
                            __html: `<p>${report.content}</p>`,
                          }}
                        />
                      </ReportSection>
                      <Button onClick={handleClicked(report)}>
                        {report.buttonText}
                      </Button>
                    </Report>
                  </Animate>
                );
              })}
            </FlexContainer>
          </ReportsContainer>
        </Container>
      </ReportsWrapper>
      {showPopUp && (
        <PopUp
          show={showPopUp}
          onClose={() => setShowPopUp(false)}
          popUp={popUp}
          downloadFile={downloadFile}
        />
      )}
    </>
  );
}
