import styled from "styled-components";
import vars from "../../styles/vars";

export const ReleaseWrapper = styled.section`
  padding: 0 0 10em 0;

  @media (max-width: 700px) {
    padding: 0;
    background-color: #00918d;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 1em 8em 1em;

  @media (max-width: 700px) {
    padding: 4em 0em 3em 0em;
  }

  p {
    line-height: 1.5;
  }
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #00918d;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  max-width: 676px;
`;

export const Video = styled.video`
  width: 100%;
`;

export const BottomContent = styled.div`
  z-index: ${vars.zIndex.report};

  p {
    color: ${vars.colors.white};

    @media (max-width: 700px) {
      font-size: 1.2rem;
      font-weight: 200;
      letter-spacing: 0.2px;
    }
  }
`;

export const BottomContentContainer = styled.div`
  max-width: 298px;
  margin-left: 2.5em;

  @media (max-width: 700px) {
    max-width: 100%;
    margin-left: 0;
    padding-inline: 2rem;

    & > div p {
      font-weight: 200;
      font-size: 1.6em;
      letter-spacing: 0.3px;
    }
  }

  h3 {
    color: ${vars.colors.white};
    font-family: ${vars.fontKamino};
    text-transform: uppercase;
    margin-bottom: 0.7em;
    font-size: 1.8em;
    line-height: 160%;
    font-weight: 400;

    @media (max-width: 700px) {
      font-size: 3rem;
      letter-spacing: 0.3px;
    }
  }
`;

export const Bottom = styled.div`
  position: relative;
  z-index: ${vars.zIndex.report};
  transform: translateY(30%);
  display: flex;
  flex-direction: row-reverse;
  height: 9.5em;

  @media (max-width: 700px) {
    transform: translateY(0%);
    display: flex;
    flex-direction: column-reverse;
    height: unset;
    gap: 2rem;
  }
`;
