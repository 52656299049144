import React from "react";
import { Card, CardT } from "../card/card";
import { CardsWrapper } from "./cards.styles";

type CardsProps = {
  data: CardT[];
  popup: string;
};

export const Cards = ({ data, popup }: CardsProps) => {
  return (
    <CardsWrapper>
      {data?.map((card, index) => (
        <Card
          key={`Card_${index}`}
          imageLeft={card.imageLeft}
          imageRight={card?.imageRight}
          imageLeftMobile={card?.imageLeftMobile}
          imageAlt={card.imageAlt}
          bgColor={card.bgColor}
          title={card?.title}
          content={card?.content}
          buttonColor={card.buttonColor}
          buttonHoverColor={card.buttonHoverColor}
          buttonCopyColor={card.buttonCopyColor}
          button1={card.button1}
          button2={card.button2}
          layout={card.layout}
          index={index}
          popup={popup}
        />
      ))}
    </CardsWrapper>
  );
};
