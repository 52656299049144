import styled from "styled-components";

export const CardsWrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  padding: 0 2rem;
  margin: 5em auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (max-width: 700px) {
    padding: 0;
    gap: 0;
    margin: 0 auto;
  }
`;
