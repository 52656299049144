import { createGlobalStyle, css } from "styled-components/macro";
import vars from "./vars";
import fonts from "./fonts";

const Reset = createGlobalStyle`
  :root {
    --root-min: 13px;
    --root-size: 3vh;
    --root-max: 20px;
    @media (orientation: portrait) {
      --root-size: 3vw;
    }
  }

  ${fonts}
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  html {
    font-size: clamp(var(--root-min), var(--root-size), var(--root-max));
    font-family: ${vars.fontQuaternary};
    font-weight: normal;
    line-height: 1.2;
  }

  p {
    font-size: 0.8em;
    font-family: ${vars.fontSecondary};
  }

  h2 {
    font-size: 1.8em;
  }

  h3 {
    font-size: 1.1em;
  }

  .wd-100 {
    @media (max-width: 900px) {
      width: 100%
    }
  }

  .Animate {
    opacity: 0;
    position: relative;
    z-index: 1;
  }

  .Animate--show {
    opacity: 1;
    position: relative;
    z-index: 1;
  }


  .lazy-load {
    --duration: .7s;
    --translate-y: 50px;

    @keyframes lazy-load {
      0% {
        opacity: 0;
        transform: scale(1.2);
      }

      100% {
        opacity: 1;
        transform: scale(1);
      }
    }

    opacity: 0;
    animation: lazy-load var(--duration) ease-in-out forwards;

    ${Array.from({ length: 20 }).map((_, i) => {
      return css`
        &-${i} {
          animation-delay: calc((var(--duration) * 0.4) * ${i});
        }
      `;
    })}
  }

  .scale-in {
    --duration: .8s;

    @keyframes scale-in {
      from {
        opacity: 0;
        transform: scale(1.2);
      }

      to {
        opacity: 1;
        transform: scale(1);
      }
    }

    opacity: 0;
    transform: scale(1.2) translate(-50%, -50%);
    animation: scale-in var(--duration) ease-in-out forwards;

    ${Array.from({ length: 20 }).map((_, i) => {
      return css`
        &-${i} {
          animation-delay: calc((var(--duration) * 0.9) * ${i});
        }
      `;
    })}
  }

  .fade-up {
    --duration: .7s;
    --translate-y: 50px;

    @keyframes fade-up {
      from {
        opacity: 0;
        transform: translateY(var(--translate-y));
      }

      to {
        opacity: 1;
        transform: translateY(0%);
      }
    }

    opacity: 0;
    transform: translateY(var(--translate-y));
    animation: fade-up var(--duration) ease-in-out forwards;

    ${Array.from({length: 20}).map((_, i) => {
      return css`
        &-${i} {
          animation-delay: calc((var(--duration) * 0.4) * ${i});
        }
      `;
    })}
  }


`;

export default Reset;
