import { gql } from "@apollo/client";
import client from "./client";

export default async function getHomePage() {
  try {
    const { data } = await client.query({
      query: gql`
        # Write your query or mutation here
        query Homes {
          homes {
            data {
              attributes {
                header {
                  backgroundColor {
                    color
                    id
                  }
                  title
                  content
                  artist {
                    id
                    artist {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
                report {
                  image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  backgroundColor {
                    id
                    color
                  }
                  popUp
                  reportFile {
                    id
                    title
                    content
                    type
                    buttonText
                    file {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                    link
                  }
                }
                cards {
                  title
                  content
                  imageLeft {
                      data {
                        attributes {
                          url
                        }
                      }
                  }
                  imageRight {
                      data {
                        attributes {
                          url
                        }
                      }
                  }
                  imageLeftMobile {
                      data {
                        attributes {
                          url
                        }
                      }
                  }
                  imageAlt
                  bgColor
                  buttonColor
                  buttonHoverColor
                  buttonCopyColor
                  layout
                  button1 {
                    text
                    link
                    file {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                  button2 {
                    text
                    link
                    file {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
                graphic {
                  title
                  chart {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  content
                  button {
                    id
                    text
                    file {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                    link
                  }
                }
                release {
                  backgroundColors {
                    color
                    id
                  }
                  title
                  content
                  button {
                    text
                    link
                  }
                  video {
                    source
                    title
                    content
                  }
                }
              }
            }
          }
        }
      `,
    });

    if (data.homes.data.length === 0) {
      return { data: null, errors: { server: "Server Error" }, valid: false };
    }

    if (data.homes.data) {
      data.homes.data = (data?.homes.data ?? [])[0] ?? null;
    }

    return { data: data.homes.data.attributes, errors: {}, valid: true };
  } catch (error) {
    return { data: null, errors: error, valid: false };
  }
}
