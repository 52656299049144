import styled from "styled-components";
import vars from "../../styles/vars";

export const PageWrapper = styled.main``;

export const CookieBannerWrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 10000000;
  width: 100vw;
  background-color: ${vars.colors.white};
  opacity: 0.9;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(20vh);
  transition: transform 2s ease-in, visibility 2s ease-in;
  & p {
    font-size: 0.8em;
    text-align: center;
    padding-bottom: 1rem;
    color: ${vars.colors.black};
  }
`;

export const ContentWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    background-color: ${vars.colors.primary};
    color: ${vars.colors.white};
    text-transform: unset;
    cursor: pointer;
    padding: 0.7em 2em;
    border: none;

    &:hover {
      background-color: #0038ff;
      color: ${vars.colors.white};
    }
  }
`;
