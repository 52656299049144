import React from "react";

type Props = {
  className: string;
  children: any;
  isVisible?: boolean;
};

export default function Animate({
  className,
  children,
  isVisible = false,
}: Props) {
  return (
    <div className={`${isVisible ? `Animate--show  ${className}` : "Animate"}`}>
      {children}
    </div>
  );
}
