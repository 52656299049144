import React from "react";
import { CardLeft, CardRight, CardWrapper } from "./card.styles";
import PopUp from "../popup/popup";
import Button from "../button/button";
import Animate from "../animate/animate";
import { useInView } from "react-intersection-observer";

export type CardT = {
  imageLeft: {
    data: {
      attributes: {
        url: string;
      };
    };
  };
  imageRight?: {
    data: {
      attributes: {
        url: string;
      };
    };
  };
  imageLeftMobile?: {
    data: {
      attributes: {
        url: string;
      };
    };
  };
  imageAlt: string;
  bgColor: string;
  title?: string;
  content?: string;
  buttonColor: string;
  buttonHoverColor: string;
  buttonCopyColor: string;
  button1: {
    text: string;
    file: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
  };
  button2: {
    text: string;
    file: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
  };
  layout: string;
};

interface CardTProps extends CardT {
  index: number;
  popup: string;
}

export const Card = ({
  index,
  imageLeft,
  imageRight,
  imageLeftMobile,
  imageAlt,
  bgColor,
  title,
  content,
  buttonColor,
  buttonHoverColor,
  buttonCopyColor,
  button1,
  button2,
  layout,
  popup,
}: CardTProps) => {
  const [showPopUp, setShowPopUp] = React.useState(false);

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <>
      <CardWrapper $layout={layout} $bgColor={bgColor} ref={ref}>
        <CardLeft
          $bgImage={imageLeft?.data?.attributes?.url}
          $bgImageLeftMobile={imageLeftMobile?.data?.attributes?.url}
          $layout={layout}
        >
          <h2>{imageAlt}</h2>
        </CardLeft>
        <CardRight
          $bgImage={imageRight?.data?.attributes?.url}
          $index={index}
          $buttonColor={buttonColor}
          $buttonHoverColor={buttonHoverColor}
          $buttonCopyColor={buttonCopyColor}
          $layout={layout}
        >
          <Animate className={`fade-up`} isVisible={inView}>
            {title && <h3>{title}</h3>}
            {content && <p>{content}</p>}
            <a
              href={button1.file?.data?.attributes?.url}
              target="_blank"
              rel="noreferrer"
            >
              {button1.text}
            </a>
            <Button
              onClick={() => setShowPopUp(true)}
              buttonColor={buttonColor}
              buttonHoverColor={buttonHoverColor}
              buttonCopyColor={buttonCopyColor}
            >
              {button2.text}
            </Button>
          </Animate>
        </CardRight>
      </CardWrapper>
      {showPopUp && (
        <PopUp
          show={showPopUp}
          onClose={() => setShowPopUp(false)}
          popUp={popup}
          downloadFile={button2.file?.data?.attributes?.url}
        />
      )}
    </>
  );
};
