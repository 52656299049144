import styled from "styled-components";
import vars from "../../styles/vars";

type Props = {
  show: boolean;
};

export const PopUpBackground = styled.div<Props>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(000, 000, 000, 0.6);
  z-index: ${vars.zIndex.reportPopUp};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  opacity: ${({ show }) => (show ? "1" : "0")};
`;

export const PopUpBox = styled.div<Props>`
  max-width: 436px;
  background-color: ${vars.colors.white};
  font-family: ${vars.fontFraunces};
  padding: 3em 2.2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  opacity: ${({ show }) => (show ? "2" : "0")};
  transform: ${({ show }) => (show ? "scale(1)" : "scale(1.2)")};
  transition: opacity 0.2s ease-in, transform 0.5s ease-in;
  pointer-events: ${({ show }) => (show ? "all" : "none")};

  label {
    display: flex;
    align-items: center;
    margin: 1em 0 1.5em 0;
    cursor: pointer;
  }

  input {
    cursor: pointer;
  }

  span {
    color: ${vars.colors.primary};
    line-height: 1.5;
    font-size: 0.6em;
    margin-left: 0.5em;

    @media (max-width: 700px) {
      font-size: 1.2rem;
    }
  }
`;

export const Close = styled.button`
  position: absolute;
  top: 1em;
  right: 1em;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 700;
`;

export const PopUpContent = styled.div`
  p,
  a {
    color: ${vars.colors.primary};
    font-weight: 700;
    line-height: 1.5;

    @media (max-width: 700px) {
      font-size: 1.5rem;
    }
  }
`;
