import { ApolloClient, InMemoryCache } from "@apollo/client";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default new ApolloClient({
  uri: `${API_ENDPOINT}/graphql`,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});
