import styled, { css } from "styled-components";
import vars from "../../styles/vars";
import { ContentWrapper } from "../content/styles";

export const HeaderWrapper = styled.header`
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  background-color: #00918d;
`;

export const TitleContainer = styled.div`
  top: 23%;
  left: 45%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: ${vars.zIndex.header};
  width: 100%;
  max-width: 800px;
  margin: auto;

  @media (max-width: 700px) {
    position: static;
    left: unset;
    top: unset;
    transform: translate(0, 0);
    margin: 0;
    max-width: 20rem;
    margin: 0 auto;
  }
`;

export const ParagraphContainer = styled.div`
  bottom: -3%;
  right: -5%;
  position: absolute;
  font-family: ${vars.fontFraunces};
  z-index: ${vars.zIndex.header};
  width: 100%;
  max-width: 800px;
  margin: auto;

  @media (min-width: 1300px) {
    bottom: 5%;
    right: -4%;
  }

  @media (max-width: 850px) {
    bottom: unset;
    right: unset;
    position: static;
    margin: 0;
    font-size: 1.4em;
    font-weight: 200;
    letter-spacing: 0.3px;
    line-height: 120%;
    margin-bottom: 1em;
  }
`;

export const HeaderInner = styled.section`
  padding: 1em 0 2em 0;
  min-height: 100vh;
  position: relative;

  @media (min-width: 1300px) {
    transform: translateY(5%);
    padding: 2em 0;
  }

  @media (max-width: 850px) {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  ${ContentWrapper} {
    max-width: 342px;
    margin-left: auto;
    transform: translate(-20%, -50%);
    p {
      font-family: ${vars.fontSecondary};
      color: ${vars.colors.white};
    }

    @media (max-width: 850px) {
      transform: translate(0%, 0%);
      text-align: justify;
      max-width: 100%;
      padding-inline: 1em;
    }
  }
`;

export const LogoWrapper = styled.div`
  @media (min-width: 1300px) {
    transform: translateY(-80%);
  }

  @media (max-width: 850px) {
    background-color: #018480;
    padding: 1em 0;
    display: flex;
    justify-content: center;
    z-index: 5;
  }
`;

export const Logo = styled.img``;

export const ArtistsWrapper = styled.div`
  padding: 1em 0;
  position: relative;
  width: 100%;

  @media (max-width: 1300px) {
    transform: translateX(-8%);
  }

  @media (max-width: 1200px) {
    transform: translateX(-12%);
  }

  @media (max-width: 1100px) {
    transform: translateX(-20%);
  }

  @media (max-width: 1050px) {
    transform: translateX(-25%);
  }

  @media (max-width: 1000px) {
    transform: translateX(-40%);
  }

  @media (max-width: 900px) {
    transform: translateX(-55%);
  }

  @media (max-width: 850px) {
    height: 50vh;
    transform: translateX(0%);
  }
`;

type ImageProps = {
  index: number;
};

export const Image = styled.img<ImageProps>``;

type Props = {
  index: number;
};

export const Artist = styled.div<Props>`
  position: absolute;

  img {
    width: 100%;
  }

  ${({ index }) => {
    switch (index) {
      case 1:
        return css`
          width: 99em;

          & img {
            transform: translate(-16%, 68%);
          }

          @media (max-width: 850px) {
            width: 85em;
            & img {
              transform: translate(-32%, 145%);
            }
          }

          @media (max-width: 385px) {
            width: 60em;
            & img {
              transform: translate(-31%, 127%);
            }
          }
        `;
      case 2:
        return css`
          // dua lipa
          width: 30em;

          & img {
            transform: translate(80%, -55%);
          }

          @media (max-width: 850px) {
            width: 22.5em;
            & img {
              transform: translate(25%, -43%);
            }
          }

          @media (max-width: 385px) {
            width: 18.5em;
            & img {
              transform: translate(29%, -42%);
            }
          }
        `;
      case 3:
        return css`
          width: 17em;

          & img {
            transform: translate(258%, -70%);
          }

          @media (max-width: 850px) {
            width: 16em;
            & img {
              transform: translate(110%, -25%);
            }
          }

          @media (max-width: 385px) {
            width: 12em;
            & img {
              transform: translate(150%, -45%);
            }
          }
        `;
      case 4:
        return css`
          // group
          width: 17.5em;

          & img {
            transform: translate(253%, 34%);
          }

          @media (max-width: 850px) {
            width: 16em;
            & img {
              transform: translate(115%, 135%);
            }
          }

          @media (max-width: 385px) {
            width: 12em;
            & img {
              transform: translate(155%, 88%);
            }
          }
        `;
      case 5:
        return css`
          // miley
          width: 18em;

          & img {
            transform: translate(197%, -10%);
          }

          @media (max-width: 850px) {
            width: 14em;
            & img {
              transform: translate(86%, 36%);
            }
          }

          @media (max-width: 385px) {
            width: 11em;
            & img {
              transform: translate(116%, 9%);
            }
          }
        `;
      case 6:
        return css`
          // taylor
          width: 38em;

          & img {
            transform: translate(35%, -27%);
          }

          @media (max-width: 850px) {
            width: 30em;
            & img {
              transform: translate(-16%, 0%);
            }
          }

          @media (max-width: 385px) {
            width: 23em;
            & img {
              transform: translate(-6%, -6%);
            }
          }
        `;
      case 7:
        return css`
          //red
          width: 18em;

          & img {
            transform: translate(80%, 33%);
          }

          @media (max-width: 850px) {
            width: 14.5em;
            & img {
              transform: translate(-13%, 113%);
            }
          }

          @media (max-width: 385px) {
            width: 10.5em;
            & img {
              transform: translate(-6.3%, 90%);
            }
          }
        `;
      case 8:
        return css`
          //sza
          width: 16em;

          & img {
            transform: translate(286.5%, 14%);
          }

          @media (max-width: 850px) {
            width: 15em;
            & img {
              transform: translate(138%, 57%);
            }
          }

          @media (max-width: 385px) {
            width: 11em;
            & img {
              transform: translate(168%, 37%);
            }
          }
        `;
      case 9:
        return css`
          // zach
          width: 24em;

          & img {
            transform: translate(135.5%, 38%);
          }

          @media (max-width: 850px) {
            width: 19em;
            & img {
              transform: translate(65%, 124%);
            }
          }

          @media (max-width: 385px) {
            width: 15em;
            & img {
              transform: translate(78%, 82%);
            }
          }
        `;
      case 10:
        return css`
          // karol g
          width: 14.5em;

          & img {
            transform: translate(180%, 70%);
          }

          @media (max-width: 850px) {
            width: 12em;
            & img {
              transform: translate(50%, 95%);
            }
          }

          @media (max-width: 385px) {
            width: 8em;
            & img {
              transform: translate(80%, 95%);
            }
          }
        `;
    }
  }}
`;
